import IRoute from "../types/route";
import NotFound from "../common/notFound";
import SidebarLayout from "../common/layouts.ts/SidebarLayout";
import ChangePasswordLazy from "./ChangePasswordLazy";
import UserProfileLazy from "./UserProfileLazy";
import AdminDetailsLazy from "./AdminDetailsLazy";
import AdminOverviewLazy from "./AdminOverviewLazy";
import FolderDetailsLazy from "./FolderDetailsLazy";
import FoldersOverviewLazy from "./FoldersOverviewLazy";
import TeamDetailsLazy from "./TeamDetailsLazy";
import TeamsLazy from "./TeamsLazy";
import TargetsSurveyLazy from "./TargetsSurveyLazy";
import PositionDetailsLazy from "./PositionDetailsLazy";
import PositionsOverviewLazy from "./PositionsOverviewLazy";
import PersonDetailsLazy from "./PersonDetailsLazy";
import PeopleOverviewLazy from "./PeopleOverviewLazy";
import DashboardLazy from "./DashboardLazy";
import Settings from "../components/Settings";

const routes: IRoute[] = [
  {
    layout: SidebarLayout,
    name: "Sidebar Layout",
    path: "/",
    routes: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardLazy,
      },
      {
        path: "/people",
        name: "People",
        component: PeopleOverviewLazy,
      },
      {
        path: "/people/:id",
        name: "People Details",
        component: PersonDetailsLazy,
      },
      {
        path: "/positions",
        name: "Positions",
        component: PositionsOverviewLazy,
      },
      {
        path: "/positions/:targetId",
        name: "Position Details",
        component: PositionDetailsLazy,
      },
      {
        path: "/positions/survey/:targetId",
        name: "Targets Survey",
        component: TargetsSurveyLazy,
      },
      {
        path: "/folders",
        name: "Folders",
        component: FoldersOverviewLazy,
      },
      {
        path: "/folders/:groupId",
        name: "Folder Details",
        component: FolderDetailsLazy,
      },
      {
        path: "/teams",
        name: "Teams",
        component: TeamsLazy,
      },
      {
        path: "/teams/:groupId",
        name: "Team Details",
        component: TeamDetailsLazy,
      },
      {
        path: "/users/",
        name: "Users",
        component: AdminOverviewLazy,
      },
      {
        path: "/users/:userId?",
        name: "User Details",
        component: AdminDetailsLazy,
      },
      {
        path: "/profile",
        name: "Profile",
        component: UserProfileLazy,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "/change-password",
        name: "Change Password",
        component: ChangePasswordLazy,
      },
      {
        path: "/password-forget",
        name: "Password Forget",
        component: ChangePasswordLazy,
      },
      {
        path: "*",
        name: "Not Found",
        component: NotFound,
      },
    ],
  },
];

export default routes;
