import React from "react";
import { X } from "@phosphor-icons/react";

interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    confirm: () => void;
    question: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onClose,
    confirm,
    question,
}) => {
    if (!isOpen) return null;

    return (
        <div
            className="modal-backdrop"
            role="dialog"
            aria-modal="true"
            aria-labelledby="confirmation-modal-title"
        >
            <div className="new-item-modal-content">
                <button
                    className="modal-close-icon modal-close-icon-sm"
                    onClick={onClose}
                    type="button"
                    aria-label="Close"
                >
                    <X />
                </button>

                <h2 id="confirmation-modal-title">Confirmation</h2>

                <div className="confirmation-question">{question}</div>

                <div className="modal-actions">
                    <button
                        className="button button-primary"
                        type="button"
                        onClick={confirm}
                    >
                        Yes
                    </button>
                    <button
                        className="button button-cancel"
                        type="button"
                        onClick={onClose}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
