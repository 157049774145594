
import { X, Tag, Plus } from '@phosphor-icons/react';
import { useRef, useState } from 'react';
import useOutsideClick from '../hooks/useOutsideClick';

interface IProps {
    selectedTags: string[];
    availableTags: string[];
    addTagHandler: (tag: string) => void;
    removeTagHandler: (tag: string) => void;
}

const TagsSelector = ({
    selectedTags,
    availableTags,
    addTagHandler,
    removeTagHandler,
}: IProps) => {

    const [showTagDropdown, setShowTagDropdown] = useState(false);
    const tagDropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick(tagDropdownRef, () => showTagDropdown && setShowTagDropdown(false));

    return (
        <>
            {selectedTags && (
                <div className="tags-container" style={{ marginLeft: '0px' }}>
                    {selectedTags.map((tag) => (
                        <div key={tag} className="tag-chip">
                            <Tag size={12} weight="fill" className="tag-icon" />
                            {tag}
                            <button
                                type='button'
                                className="tag-menu-button"
                                onClick={() => removeTagHandler?.(tag)}
                            >
                                <X size={12} weight="bold" />
                            </button>
                        </div>
                    ))}
                    {availableTags && availableTags.length > 0 && (
                        <div className="tag-dropdown-container" ref={tagDropdownRef}>
                            <button
                                type='button'
                                className="add-tag-button"
                                onClick={() => setShowTagDropdown(!showTagDropdown)}
                            >
                                + Add Tag
                            </button>
                            {showTagDropdown && (
                                <div className="tag-dropdown">
                                    {availableTags
                                        .filter(tag => !selectedTags.includes(tag))
                                        .map(tag => (
                                            <button
                                                type='button'
                                                key={tag}
                                                className="tag-dropdown-item"
                                                onClick={() => {
                                                    addTagHandler?.(tag);
                                                    setShowTagDropdown(false);
                                                }}
                                            >
                                                <Plus size={10} weight="bold" />
                                                {tag}
                                            </button>
                                        ))
                                    }
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default TagsSelector;