import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavigationLinks from './NavigationLinks';
import { ReactComponent as Logo } from '../assets/logo-aptive-index-white-square.svg';
import debounce from 'lodash/debounce';

interface SidebarProps {
    darkMode: 'light' | 'dark' | 'system';
    setDarkMode: (mode: 'light' | 'dark' | 'system') => void;
}

const Sidebar: React.FC<SidebarProps> = ({ darkMode, setDarkMode }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleMouseEnter = useCallback(
        debounce(() => {
            setIsExpanded(true);
        }, 150),
        []
    );

    const handleMouseLeave = useCallback(
        debounce(() => {
            setIsExpanded(false);
        }, 100),
        []
    );

    return (
        <aside
            className={`sidebar ${isExpanded ? 'expanded' : ''} ${darkMode === 'dark' ? 'dark-mode' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="sidebar-logo-container">
                <Link to="/">
                    <Logo className="sidebar-logo" />
                </Link>
            </div>
            <NavigationLinks
                handleLogout={handleLogout}
                darkMode={darkMode}
                setDarkMode={setDarkMode}
            />
        </aside>
    );
}

export default Sidebar;